import { RouteLocationNormalized } from 'vue-router';
import { useUserSessionStore } from '@/store/user_session.store.ts';

export const authGuard = async (
  to: RouteLocationNormalized,
  // @ts-ignore
  from: RouteLocationNormalized,
): Promise<string | true> => {
  const userSession = useUserSessionStore();
  const isAuthRoute = to.path.startsWith('/auth/');
  const requiresAuth = to.meta.requiresAuth;

  try {
    // Si la ruta requiere autenticación y el usuario no está logueado, redirigir al login
    if (requiresAuth && !userSession.isLoggedIn) {
      return '/auth/login';
    }
    // Si la ruta es de autenticación y el usuario está logueado, redirigir al dashboard
    if (isAuthRoute && userSession.isLoggedIn) {
      return '/';
    }
    // En cualquier otro caso, permitir la navegación
    return true;
  } catch (error) {
    console.error('Error in auth guard:', error);
    // En caso de error, redirigir al login
    return '/auth/login';
  }
};
