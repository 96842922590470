<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <Suspense>
    <template #default>
      <Toaster />
    </template>
  </Suspense>
</template>

<script lang="ts" setup>
  import { defineAsyncComponent } from 'vue';

  const Toaster = defineAsyncComponent(() => import('vue-sonner').then((m) => m.Toaster));
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
