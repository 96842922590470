import { ref, computed } from 'vue';

export const DEFAULT_LANGUAGE_CODE =
  getLanguageCode(navigator.language) || import.meta.env.VITE_DEFAULT_LOCALE || 'en';

export interface LanguageLocale {
  code: string;
  name: string;
}

export enum NameCodeLanguageEnum {
  English = 'en',
  Spanish = 'es',
  Chinese = 'zh',
  German = 'de',
  French = 'fr',
  Portuguese = 'pt',
}

export enum NameLanguageEnum {
  en = 'English',
  es = 'Español',
  zh = '中文',
  de = 'Deutsch',
  fr = 'Français',
  pt = 'Português',
}

export const DateFormatFn = (locale: NameCodeLanguageEnum, time: boolean) => {
  const formats = {
    [NameCodeLanguageEnum.Spanish]: time ? 'd/m/Y H:i' : 'd/m/Y',
    [NameCodeLanguageEnum.Chinese]: time ? 'Y/m/d H:i' : 'Y/m/d',
    [NameCodeLanguageEnum.German]: time ? 'd.m.Y H:i' : 'd.m.Y',
    [NameCodeLanguageEnum.French]: time ? 'd/m/Y H:i' : 'd/m/Y',
    [NameCodeLanguageEnum.Portuguese]: time ? 'd/m/Y H:i' : 'd/m/Y',
    [NameCodeLanguageEnum.English]: time ? 'm/d/Y H:i' : 'm/d/Y',
  };
  return formats[locale] || formats[NameCodeLanguageEnum.English];
};

export const DateFormatVueFn = (locale: NameCodeLanguageEnum, time: boolean) => {
  const formatStrings = {
    [NameCodeLanguageEnum.Spanish]: time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',
    [NameCodeLanguageEnum.Chinese]: time ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd',
    [NameCodeLanguageEnum.German]: time ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy',
    [NameCodeLanguageEnum.French]: time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',
    [NameCodeLanguageEnum.Portuguese]: time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',
    [NameCodeLanguageEnum.English]: time ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy',
  };
  return formatStrings[locale] || formatStrings[NameCodeLanguageEnum.English];
};

export const languages = ref<LanguageLocale[]>([]);

export const setLanguages = () => {
  languages.value = Object.values(NameCodeLanguageEnum).map((code) => ({
    code,
    name: NameLanguageEnum[code],
  }));
};

export const getLanguages = computed(() => languages.value);

export function getLanguageCode(code: string): string {
  return code.split('-')[0];
}

setLanguages();
