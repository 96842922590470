import { defineStore } from 'pinia';
import { usePreferredDark, useStorage } from '@vueuse/core';
import { computed, onMounted, watch } from 'vue';

export type ColorSchema = 'system' | 'dark' | 'light';

export const useDarkMode = defineStore('darkMode', () => {
  const preferredDark = usePreferredDark();
  const colorSchema = useStorage<ColorSchema>('color-schema', 'system');

  const isDark = computed(() =>
    colorSchema.value === 'system' ? preferredDark.value : colorSchema.value === 'dark',
  );

  const updateTheme = (dark: boolean) => {
    document.documentElement.classList.toggle('dark', dark);
  };

  const setColorSchema = (schema: ColorSchema) => {
    colorSchema.value = schema;
    updateSystemListener(schema);
    updateTheme(isDark.value);
  };

  const cycleColorSchema = () => {
    const schemas: ColorSchema[] = ['system', 'light', 'dark'];
    setColorSchema(schemas[(schemas.indexOf(colorSchema.value) + 1) % schemas.length]);
  };

  let mediaQuery: MediaQueryList | null = null;

  const handleSystemChange = (e: MediaQueryListEvent) => {
    if (colorSchema.value === 'system') {
      updateTheme(e.matches);
    }
  };

  const updateSystemListener = (schema: ColorSchema) => {
    if (schema === 'system') {
      addSystemListener();
    } else {
      removeSystemListener();
    }
  };

  const addSystemListener = () => {
    if (!mediaQuery) {
      mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQuery.addEventListener('change', handleSystemChange);
    }
  };

  const removeSystemListener = () => {
    if (mediaQuery) {
      mediaQuery.removeEventListener('change', handleSystemChange);
      mediaQuery = null;
    }
  };

  watch(isDark, updateTheme);

  onMounted(() => {
    updateTheme(isDark.value);
    updateSystemListener(colorSchema.value);
  });

  const colorSchemaPreloadScript = `
    (function() {
      const colorSchema = localStorage.getItem('color-schema') || 'system';
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const isDark = colorSchema === 'system' ? prefersDark : colorSchema === 'dark';
      document.documentElement.classList.toggle('dark', isDark);
    })();
  `;

  return {
    isDark,
    colorSchema,
    setColorSchema,
    cycleColorSchema,
    colorSchemaPreloadScript,
  };
});
