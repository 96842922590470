import { Router } from 'vue-router';
import { Pinia } from 'pinia';
import { useUserSessionStore } from '@/store/user_session.store';
import { useProductSessionStore } from '@/store/product_session.store';
import type { ProductExtCompose } from '@/core/models/product_compose.model';

interface UserModel {
  id: string;
  email: string;
  name: string;
  UserConfig: Record<string, any>;
}

interface PassDataExt {
  url: string;
  params?: string;
  data: {
    token: string;
    refreshToken: string;
    user: UserModel | string;
    products?: ProductExtCompose[];
  };
}

const PIRATRACK_EVENTS = {
  PASS_DATA: 'piratrack:pass:data:ext:session',
  DATA_CONFIRMATION: 'piratrack:data:received:confirmation',
  REQUEST_RESEND: 'piratrack:request:data:resend',
  GET_DATA: 'piratrack:get:data:ext'
};

export function setupMessageHandler(router: Router, pinia: Pinia): () => void {
  const userSessionStore = useUserSessionStore(pinia);
  const productSessionStore = useProductSessionStore(pinia);

  const decodeUnicode = (str: string): string => {
    return decodeURIComponent(atob(str).split('').map(c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
  };

  const handleMessage = (event: MessageEvent): void => {
    console.log('Message received:', event);
    console.log('Origin:', event.origin);
    console.log('Window origin:', window.origin);

    switch (event.data.type) {
      case PIRATRACK_EVENTS.PASS_DATA:
        handlePassData(event);
        break;
      case PIRATRACK_EVENTS.REQUEST_RESEND:
        requestDataResend(event);
        break;
    }
  };

  const handlePassData = (event: MessageEvent): void => {
    try {
      const parsedData: PassDataExt = JSON.parse(decodeUnicode(event.data.data));
      const userData: UserModel = typeof parsedData.data.user === 'string'
        ? JSON.parse(parsedData.data.user)
        : parsedData.data.user;
      const { token, refreshToken, products } = parsedData.data;

      if (userData && token && refreshToken) {
        console.log('UserConfig:', userData.UserConfig);
        router.push('/history');
        userSessionStore.setUser(userData);
        userSessionStore.setToken(token);
        userSessionStore.setRefreshToken(refreshToken);
      }

      if (products?.length) {
        console.log('Products:', products);
        productSessionStore.setProductsExt(products);
      }

      sendConfirmation(event.origin, 'success');
    } catch (error) {
      console.error('Error processing message:', error);
      sendConfirmation(event.origin, 'error', 'Error processing data');
    }
  };

  const sendConfirmation = (origin: string, status: 'success' | 'error', message?: string): void => {
    window.parent.postMessage({
      type: PIRATRACK_EVENTS.DATA_CONFIRMATION,
      status,
      message,
      // @ts-ignore
      uid: event.data.uid
    }, origin);
  };

  const requestDataResend = (event: MessageEvent): void => {
    window.parent.postMessage({ type: PIRATRACK_EVENTS.GET_DATA }, event.origin);
  };

  window.addEventListener('message', handleMessage);

  // Request initial data
  window.parent.postMessage({ type: PIRATRACK_EVENTS.GET_DATA }, '*');

  return () => window.removeEventListener('message', handleMessage);
}
