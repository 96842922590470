import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { ProductExtCompose } from '@/core/models/product_compose.model';

export const useProductSessionStore = defineStore('productSessionStore', () => {
  const productsExt = ref<ProductExtCompose[]>([]);

  const setProductsExt = (products: ProductExtCompose[]) => {
    console.log('Products set:', products);
    productsExt.value = products;
  };

  const addProduct = (product: ProductExtCompose) => {
    const existingIndex = productsExt.value.findIndex(
      (p) => p.product.skuProductShop === product.product.skuProductShop,
    );
    if (existingIndex !== -1) {
      productsExt.value[existingIndex] = { ...productsExt.value[existingIndex], ...product };
    } else {
      productsExt.value.push(product);
    }
    console.log('Product added/updated:', product);
  };

  return {
    productsExt,
    setProductsExt,
    addProduct,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductSessionStore, import.meta.hot));
}
