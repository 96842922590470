import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import i18n from '@/main.ts';
import { authGuard } from '@/core/guards/auth.guard.ts';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'History',
        component: () => import('@/modules/history/views/HistoryView.vue'),
        meta: {
          requiresAuth: false,
          title: 'routes.history.title',
          description: 'routes.history.description',
        },
      },
      {
        path: '/history',
        name: 'History',
        component: () => import('@/modules/history/views/HistoryView.vue'),
        meta: {
          requiresAuth: false,
          title: 'routes.history.title',
          description: 'routes.history.description',
        },
      },
      {
        path: 'tracking',
        name: 'Tracking',
        component: () => import('@/modules/tracking/views/TrackingView.vue'),
        meta: {
          requiresAuth: false,
          title: 'routes.tracking.title',
          description: 'routes.tracking.description',
        },
      },
      {
        path: 'coupons',
        name: 'Coupons',
        component: () => import('@/modules/coupons/views/CouponsView.vue'),
        meta: {
          requiresAuth: false,
          title: 'routes.coupons.title',
          description: 'routes.coupons.description',
        },
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/modules/account/views/AccountView.vue'),
        meta: {
          requiresAuth: false,
          title: 'routes.account.title',
          description: 'routes.account.description',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'routes.not_found.title',
      description: 'routes.not_found.description',
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_ROUTER_BASE),
  routes,
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalized, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.meta.scrollToTop === false) {
      return false;
    }

    // If no children detected, scroll to the top of the page
    if (to.path === from.path && JSON.stringify(to.query) !== JSON.stringify(from.query)) {
      return false;
    }

    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { t } = i18n.global;

  try {
    // Aplicar el guard de autenticación
    const authResult = await authGuard(to, from);

    if (typeof authResult === 'string') {
      // Si authGuard devuelve una string, es una ruta de redirección
      next(authResult);
      return;
    }

    // Traducir título y descripción para SEO
    const translatedTitle = t(to.meta.title as string);
    const translatedDescription = t(to.meta.description as string);
    document.title = `${translatedTitle || 'Página'} - ${import.meta.env.VITE_APP_NAME}`;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', translatedDescription || '');
    }

    // Permitir la navegación
    next();
  } catch (error) {
    console.error('Error in navigation guard:', error);
    next('/auth/login');
  }
});

router.onError((error) => {
  console.error('Error de navegación:', error);
  // Here you can add additional logic to handle errors in production
  // For example, sending the error to a monitoring service
});

// Production configuration
if (import.meta.env.PROD) {
  // Disable Vue Router warnings
  const originalPush = router.push;
  router.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
      if (err.name !== 'NavigationDuplicated') throw err;
    });
  };
}

export default router;
