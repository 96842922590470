import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import App from './App.vue';
import router from './router';
import './style.scss';
import { useDarkMode } from '@/store/dark_mode.store.ts';
import { DEFAULT_LANGUAGE_CODE } from '@/common/languages.common.ts';
import { setupMessageHandler } from '@/messageHandler.ts';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_GA_MEASUREMENT_ID,
};

let firebaseApp;
let analytics: any;

if (import.meta.env.PROD && import.meta.env.VITE_ENABLE_ANALYTICS === 'true') {
  firebaseApp = initializeApp(firebaseConfig);
  analytics = getAnalytics(firebaseApp);
  console.log('Firebase y Analytics initialized');
} else {
  console.log(
    'Firebase y Analytics not initialized. Check if VITE_ENABLE_ANALYTICS is true and if you are in production mode',
  );
}

const getInitialLocale = () => {
  const storedLocale = localStorage.getItem('locale');
  return storedLocale || DEFAULT_LANGUAGE_CODE;
};

const i18n = createI18n({
  legacy: false,
  locale: getInitialLocale(),
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages: {},
});

// Dynamically import locales
const loadLocaleMessages = async () => {
  const locales = import.meta.glob('./locales/*.json');
  for (const path in locales) {
    const matched = path.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const messages = await locales[path]();
      // @ts-ignore
      i18n.global.setLocaleMessage(locale, messages.default);
    }
  }
};

const initializeDarkMode = () => {
  const darkModeStore = useDarkMode();
  const storedColorSchema = localStorage.getItem('color-schema') as
    | 'system'
    | 'dark'
    | 'light'
    | null;

  const applyTheme = (isDark: boolean) => {
    document.documentElement.classList.toggle('dark', isDark);
  };

  const getSystemPreference = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

  const updateTheme = () => {
    const currentSchema = darkModeStore.colorSchema;
    const isDark = currentSchema === 'system' ? getSystemPreference() : currentSchema === 'dark';
    applyTheme(isDark);
  };

  const initialColorSchema = storedColorSchema || 'system';
  darkModeStore.setColorSchema(initialColorSchema);

  updateTheme();

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (_) => {
    if (darkModeStore.colorSchema === 'system') {
      updateTheme();
    } else {
      applyTheme(darkModeStore.colorSchema === 'dark');
    }
  });
};

const initializeVueApp = async () => {
  try {
    await loadLocaleMessages();
    const app = createApp(App);
    const pinia = createPinia();

    app.use(pinia).use(router).use(i18n);

    initializeDarkMode();

    setupMessageHandler(router, pinia);

    if (import.meta.env.VITE_ENABLE_ANALYTICS === 'true' && import.meta.env.PROD) {
      import('@/plugins/analytics').then(({ setupAnalytics }) => {
        setupAnalytics(router, analytics);
      });
    }

    // @ts-ignore
    if (document.querySelector('#app').__vue_app__) {
      // @ts-ignore
      document.querySelector('#app').__vue_app__.unmount();
    }
    app.mount('#app');

    // Disable console logs in production
    if (import.meta.env.PROD && import.meta.env.VITE_ENABLE_CONSOLE_LOGS !== 'true') {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
  } catch (error) {
    console.error('Error during initialization:', error);
  }
};

initializeVueApp();

export default i18n;
