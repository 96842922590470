import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { UserModel } from '@/core/models/user.model.ts';
import { EncryptionLevel } from '@/core/workers/encryption.worker.ts';

// Create a new worker
const worker = new Worker(new URL('@/core/workers/encryption.worker.ts', import.meta.url), {
  type: 'module',
});

export const useUserSessionStore = defineStore('userSessionStore', () => {
  const token = useStorage('token', '');
  const refreshToken = useStorage('refreshToken', '');
  const userEncrypted = useStorage('identity', '');
  const uid = useStorage('sessionId', '');
  const user = ref<UserModel | null>(null);

  const isLoggedIn = computed(() =>
    Boolean(token.value && refreshToken.value && userEncrypted.value && uid.value),
  );

  const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

  const workerOperation = (
    type: 'encrypt' | 'decrypt',
    data: string,
    encryptionLevel: EncryptionLevel,
  ): Promise<string> => {
    if (encryptionLevel === 'none') return Promise.resolve(data);

    return new Promise((resolve, reject) => {
      const id = generateUniqueId();
      const handleMessage = (event: MessageEvent) => {
        if (event.data.id === id) {
          worker.removeEventListener('message', handleMessage);
          event.data.type === 'error'
            ? reject(new Error(event.data.result))
            : resolve(event.data.result[0]);
        }
      };
      worker.addEventListener('message', handleMessage);
      worker.postMessage({ type, items: [data], id, encryptionLevel });
    });
  };

  const setUser = async (newUser: UserModel) => {
    newUser.fullName = `${newUser.name} ${newUser.lastname}`;
    user.value = newUser;
    userEncrypted.value = await workerOperation('encrypt', JSON.stringify(newUser), 'strong');
  };

  const initUser = async () => {
    if (!user.value && userEncrypted.value) {
      const decrypted = await workerOperation('decrypt', userEncrypted.value, 'strong');
      user.value = JSON.parse(decrypted);
    }
    return user.value;
  };

  const getUser = computed(() => user.value);

  const setToken = (newToken: string) => {
    token.value = newToken;
  };
  const setRefreshToken = (newToken: string) => {
    refreshToken.value = newToken;
  };

  const getSessionData = computed(() => ({
    token: token.value,
    refreshToken: refreshToken.value,
    user: user.value,
    uid: uid.value,
  }));

  const setSessionUid = (newUid: string) => {
    uid.value = newUid;
  };

  const logoutUser = async () => {
    token.value = '';
    refreshToken.value = '';
    userEncrypted.value = '';
    user.value = null;
    uid.value = '';
    ['token', 'refreshToken', 'identity', 'sessionId'].forEach((key) =>
      localStorage.removeItem(key),
    );
  };

  // Initialize user on store creation
  initUser();

  return {
    token,
    refreshToken,
    user,
    uid,
    isLoggedIn,
    getUser,
    getSessionData,
    setUser,
    setToken,
    setRefreshToken,
    setSessionUid,
    logoutUser,
    initUser,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSessionStore, import.meta.hot));
}
